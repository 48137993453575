<template>
  <div>
    <translate></translate>
  </div>
</template>
<script>
import translate from "@/components/translatePractice.vue";
export default {
  components: {
    translate
  },
  computed: {},
  watch: {},
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {

  },
  beforeDestroy () {
  }
};
</script>
<style lang="less" scoped>

</style>
